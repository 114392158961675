import Swiper from "../vendor/swiper";
import vars from "../_vars";
// import { throttle } from "../functions/throttle";
// import { even } from "../functions/customFunctions";

import '../vendor/lightbox';
import '../vendor/lg-video';



const { reviewsSlider, expertSlider,worksSlider} = vars;

new Swiper(worksSlider, {
  slidesPerView: 3,
  spaceBetween: 10,
  navigation: {
    nextEl: document.querySelector('.works-section-next'),
    prevEl: document.querySelector('.works-section-prev'),
  },

  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },

  breakpoints: {
    // when window width is >= 320px
    320: {
        slidesPerView: 1,
        spaceBetween: 10,
    },

    576: {
        slidesPerView: 2,
        spaceBetween: 10,
    },

    1024: {
      slidesPerView: 3,
      spaceBetween: 10,
    }
}
});
new Swiper(expertSlider, {
  slidesPerView: 2,
  spaceBetween: 45,
  navigation: {
    nextEl: document.querySelector('.expert-slider-next'),
    prevEl: document.querySelector('.expert-slider-prev'),
  },

  breakpoints: {
    // when window width is >= 320px
    320: {
        slidesPerView: 1,
        spaceBetween: 10,
    },

    767: {
        slidesPerView: 2,
        spaceBetween: 10,
    },

    1024: {
      slidesPerView: 2,
      spaceBetween: 25,
    },

    1440: {
      slidesPerView: 2,
      spaceBetween: 45,
    }
}
});

if (reviewsSlider) {
  (function () {
    "use strict";

    const breakpoint = window.matchMedia("(min-width:767px)");
    let slider;

    const breakpointChecker = function () {
      if (breakpoint.matches === true) {
        if (slider !== undefined) slider.destroy(true, true);

        return;
      } else if (breakpoint.matches === false) {
        return enableSwiper();
      }
    };

    const enableSwiper = function () {
      slider = new Swiper(reviewsSlider, {
        slidesPerView: 1.2,
        spaceBetween: 5,
        loop: true,
      });
    };

    breakpoint.addListener(breakpointChecker);
    breakpointChecker();
  })();
}



lightGallery(document.getElementById('gallery'), {
  videoMaxWidth: '1920px',
});

lightGallery(document.getElementById('works'), {
  // videoMaxWidth: '1920px'
});








