import vars from '../_vars';
import { elementHeight } from '../functions/customFunctions';

const {header,footer,formText} = vars;

elementHeight(header, "header-height");
// elementHeight(footer, "footer-height");
elementHeight(formText, "descr-size");



