import { removeClassInArray, addCustomClass, removeCustomClass } from '../functions/customFunctions'
import vars from '../_vars';
import lottie from 'lottie-web';
// const {dataName ,activeClass} = vars;

import AOS from 'aos';

AOS.init({
  disable: 'phone',
  once: true,
  duration: 800,
});
