loader();

function loader(_success) {
    const obj = document.querySelector('.preloader');

    if(obj) {
      const inner = document.querySelector('.preloader__number');
      const bar = document.querySelector('.preloader__bar');
      obj.classList.add('show');
      // page.classList.remove('show');
      let w = 0,
          t = setInterval(function() {
              w = w + 1;
              inner.textContent = w+'%';
              bar.style.width = w+'%';
              if (w === 100){
                  obj.classList.remove('show');
                  document.querySelector('header').style.opacity = '1';
                  document.querySelector('main').style.opacity = '1';
                  clearInterval(t);
                  w = 0;
                  if (_success){
                      return _success();
                  }
              }
          }, 20);
    }


}
