import './components/preloader';
import './components/animations';
import './components/validate';
import './components/getDinamicHeight';
import './components/sliders';
import './components/burger';

// import './components/modals';
// import './components/tabs';
// import './components/select';
// import './components/filtersButtons';
// import './components/replaceEl';
import './components/accordions';
import './components/anchor';
// import './components/game';
// import './components/observer';
import './components/timer';
import './components/styckyBlock';




