import { even, addMultiListener } from '../functions/customFunctions'
import vars from '../_vars';

const { accFaqBlock, accItem, accList } = vars;


function startTimer() {
  var duration = 6 * 60;

  var timer = setInterval(function() {
    var minutes = Math.floor(duration / 60);
    var seconds = duration % 60;

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    if(document.querySelector('.form-section__time span')) {
      document.querySelector('.form-section__time span').textContent = `${minutes}:${seconds}`
    }



    if (--duration < 0) {
      clearInterval(timer);
      console.log('Таймер завершен');
    }
  }, 1000);
}


if(document.querySelector('.form-section__time')) {
  startTimer();
}





