import { addMultiListener,removeClassInArray, addCustomClass, removeCustomClass } from '../functions/customFunctions'
import vars from '../_vars';

const {header} = vars
let sectionElement = document.getElementById('forma');
let elements = document.querySelectorAll('.main-form__row input');
let section = document.querySelector('.form-section');


function handleResize() {
  if (window.innerWidth < 1024) {
    if (sectionElement) {
      function setSectionToTop() {
        if (sectionElement) {
          let offsetTop = sectionElement.offsetTop - header.offsetHeight;

          window.scrollTo(0, offsetTop);
        }
      }

      elements.forEach(function(element) {
        element.addEventListener('blur', function(event) {
          if(!section.classList.contains('focus')) {
            setSectionToTop()
          }
        });
      });

    }
  }
}

handleResize();

// Отслеживать событие изменения размера окна
window.addEventListener('resize', handleResize);


