// Подключение плавной прокрутки к якорям
// https://github.com/cferdinandi/smooth-scroll
import vars from "../_vars";
const {header,activeClass} = vars;

import SmoothScroll from 'smooth-scroll';
import { addCustomClass, removeCustomClass } from "../functions/customFunctions";
const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  offset: function (toggle) {
		if (toggle.classList.contains('.main-nav__link')) {
			return header.offsetHeight;
		} else {
			return 50;
		}
	}
});

 function toggleScrollButton() {
  const scrollButton = document.querySelector('.achor-button');
  const sectionHeight = document.querySelector('.hero-section').offsetHeight;
  let scrollPosition = window.pageYOffset;

  if (scrollPosition >= (sectionHeight / 2)) {
    addCustomClass(scrollButton, activeClass)
  } else {
    removeCustomClass(scrollButton, activeClass)
  }
}


// Отслеживание события прокрутки страницы
window.addEventListener('scroll', toggleScrollButton);


