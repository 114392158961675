import { validateForms } from '../functions/validate-forms';
import vars from '../_vars';
import { modalClickHandler } from '../components/modals'
import { removeClassInArray, addCustomClass, removeCustomClass } from "../functions/customFunctions";


const rules1 = [

  {
    ruleSelector: '.input-phone',
    tel: true,
    telError: 'Введіть правильний телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заповніть телефон!'
      },
    ]
  },
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Поле має містити щонайменше 3 символи'
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заповніть ваше ПІБ!'
      }
    ]
  },
];


const afterForm = () => {
  const pageName = document.querySelector('.main-form').getAttribute('data-page');
  window.location.href = `${pageName}_success.html`;
};

const error = () => {
  console.log('Ошибка отправки')
};


if (document.querySelector('.main-form')) {
  validateForms('.main-form', rules1, afterForm, error);
}

















